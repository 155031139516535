<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" @change="handleTabs" style="margin-bottom:10px;">
      <el-radio-button :label="0">全部({{table.total_1}})</el-radio-button>
      <el-radio-button :label="1">进行中({{table.total_2}})</el-radio-button>
      <el-radio-button :label="2">已完成({{table.total_3}})</el-radio-button>
      <el-radio-button :label="3">已取消({{table.total_4}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="标题搜索:" prop="keyword">
          <el-input clearable v-model.trim="table.params.keyword" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="工种类型:" prop="kinds">
          <el-select v-model="table.params.kinds" clearable placeholder="请选择工种类型">
            <el-option
              v-for="item in setKinds"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布账号:" prop="account">
          <el-input clearable v-model.trim="table.params.account" placeholder="请输入发布账号"></el-input>
        </el-form-item>
        <el-form-item label="选择人员:" prop="worker_name">
          <el-input clearable v-model.trim="table.params.worker_name" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- 市级账号才有所属区域 -->
        <el-form-item label="所属区域" prop="area_arr" v-if="cur_area == 0">
          <el-cascader
            :popper-append-to-body="false"
            popper-class="areaCascaderWrap"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: true }">
          </el-cascader>
        </el-form-item>
        <!-- 进行中状态查询 -->
        <template v-if="table.params.status === 1">
          <el-form-item label="状态:" prop="order_status">
            <el-select v-model="table.params.order_status" clearable placeholder="请选择状态">
              <el-option
                v-for="item in orderStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- 已取消状态查询 -->
        <template v-if="table.params.status === 3">
          <el-form-item label="状态:" prop="cancel_type">
            <el-select v-model="table.params.cancel_type" clearable placeholder="请选择状态">
              <el-option
                v-for="item in cancelType"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="cur_area != 0 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:total_amount="{row}">
        <span>￥{{row.total_amount}}</span>
      </template>
      <template v-slot:province_str="{row}">
        <span >{{row.area_str}}</span>
      </template>
      <template v-slot:content="{row}">
        <span class="popSpan">{{row.content}}</span>
      </template>
      <!-- <template v-slot:settle_type="{row}">
        <span>{{settleType[row.settle_type]}}</span>
      </template> -->
      <!-- 结算方式改为定价方式 -->
      <template v-slot:price_type="{row}">
        <span>{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'}, row.price_type)}}</span>
      </template>
      <template v-slot:status="{row}">
        <span>{{row.cancel_type ? (row.status > 4 ? cancel[row.cancel_type] : applyCancel[row.cancel_type]) :status[row.status]}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>

    <detail ref="detail"></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage/index.js'
export default {
  name: 'OrderList',
  components: {
    VTable,
    Detail,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['setKinds'])
  },
  data() {
    return {
      field: [
        // { name: "order_no", label: "工单编号", width:'170', hidden: false },
        { name: "parent_kinds_str", label: "工种类型",width:'150', hidden: false },
        { name: "title", label: "招工标题",  width: '140', hidden: false },
        // { name: "content", label: "工作描述", hidden: false },
        { name: "name", label: "发布人", showTooltip:true, hidden: false },
        { name: "account", label: "发布账号", width: '140', hidden: false },
        { name: "worker_name", label: "选择人员", width: '140', hidden: false },
        { name: "create_time", label: "发布时间", width: '140', hidden: false },
        { name: "choose_time", label: "选择时间", width: '140', hidden: false },
        { name: "total_amount", label: "工单金额", hidden: false },
        { name: "price_type", label: "定价方式", width:"100", hidden: false },
        { name: "status", label: "状态",  width: '140', hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "100", hidden: false }
      ],
      field2: [
        // { name: "order_no", label: "工单编号", width:'170', hidden: false },
        { name: "parent_kinds_str", label: "工种类型",width:'150', hidden: false },
        { name: "title", label: "招工标题",  width: '140', hidden: false },
        { name: "province_str", label: "所在区域", hidden: false },
        // { name: "content", label: "工作描述", hidden: false },
        { name: "name", label: "发布人", showTooltip:true, hidden: false },
        { name: "account", label: "发布账号", width: '140', hidden: false },
        { name: "worker_name", label: "选择人员", width: '140', hidden: false },
        { name: "create_time", label: "发布时间", width: '140', hidden: false },
        { name: "choose_time", label: "选择时间", width: '140', hidden: false },
        { name: "total_amount", label: "工单金额", hidden: false },
        { name: "price_type", label: "定价方式", width:"100", hidden: false },
        { name: "status", label: "状态",  width: '140', hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "100", hidden: false }
      ],
      table: {
        loading: false,
        total_1: '',
        total_2: '',
        total_3: '',
        total_4: '',
        params: {
          workType: '',
          kinds: '',
          account: '',
          order_status: '',
          cancel_type: '',
          status: 0,
          province: '',
          city: '',
          area: '',
          area_arr: [],
          worker_name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      cancelType: [
        {id: 1,name: '老板已经取消'},
        {id: 2,name: '师傅已经取消'}
      ],
      orderStatus: [
        {id: 1,name:'待确认'},
        {id: 2, name: '待开工'},
        {id: 3,name: '待完工'},
        {id: 4,name: '待结算'},
        {id: 5, name: '申请退款中'},
        { id: 6, name: '申请取消中'}
      ],
      status:{
        0:'待确认',
        1:'待确认',
        2:'待开工',
        3:'待完工',
        4:'待结算',
        5:'已完成'
      },
      applyCancel:{
        1:"申请退款",
        2:"申请取消"
      },
      cancel:{
        1:"老板已经取消",
        2:"师傅已经取消"
      },
      areaArr:[],
      cur_area: getStorage("area"), // 区域0是县级账号，不为0者市级账号
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      // 进行中时删除cancel_type、取消中时删除order_status
      if(this.table.params.status === 1) {
        delete this.table.params.cancel_type
      } else if(this.table.params.status === 3) {
        delete this.table.params.order_status
      } else {
        delete this.table.params.cancel_type
        delete this.table.params.order_status
      }
      let _area_arr = this.table.params.area_arr;
      this.table.params.province = _area_arr[0];
      this.table.params.city = _area_arr[1];
      this.table.params.area = _area_arr[2];
      let _params = { ...this.table.params }
      delete _params.area_arr;
      this.$http.get('/government/order/list', { params: _params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
          this.table.total_3 = res.data.total_3;
          this.table.total_4 = res.data.total_4;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    }
  }
}
</script>

<style scoped>
.popSpan {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>
<style>
.areaCascaderWrap .el-cascader-menu:nth-of-type(1) .el-radio {
  display: none !important;
}
</style>