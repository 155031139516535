<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-divider content-position="left">个人信息</el-divider>
      <div class="info-top">
        <div style="text-align:left;">
          <p>工单编号: {{dataInfo.order.order_no}}</p>
          <p>发布者：{{dataInfo.job.name}}</p>
          <p>联系电话：{{dataInfo.job.account}}</p>
        </div>
        <el-avatar
          style="width: 80px; height: 80px;margin-left:50px"
          class="avatar_img"
          shape="square"
          :src="dataInfo.job.avatar"
          fit="cover">
          <img :src="require('@/assets/img/default.png')"/>
        </el-avatar>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="招工信息" :column="1" border>
        <el-descriptions-item label="发布时间" :labelStyle='labelStyle'>{{dataInfo.job.create_time}}</el-descriptions-item>
        <el-descriptions-item label="所属工种" :labelStyle='labelStyle'>{{dataInfo.job.parent_kinds_str}}</el-descriptions-item>
        <el-descriptions-item label="招工标题" :labelStyle='labelStyle'>{{dataInfo.job.title}}</el-descriptions-item>
        <!-- 若有规格则展示规格 -->
        <el-descriptions-item v-if="!!dataInfo.job.item_str" label="规格" :labelStyle='labelStyle'>{{dataInfo.job.item_str}} > {{dataInfo.job.spec_str}}</el-descriptions-item>
        <!-- 若有用工人数则展示人数 -->
        <el-descriptions-item v-if="dataInfo.job.use_num > 1" label="用工人数" :labelStyle='labelStyle'>{{dataInfo.job.use_num}}人</el-descriptions-item>
        
        <!-- kinds:190 陪诊服务 -->
        <template v-if="dataInfo.job.kinds === 190">
          <el-descriptions-item label="陪诊医院" :labelStyle='labelStyle'>{{dataInfo.job.pz_hospital}}</el-descriptions-item>
          <el-descriptions-item label="科室" :labelStyle='labelStyle'>{{dataInfo.job.pz_dep}}</el-descriptions-item>
          <el-descriptions-item label="就诊时间" :labelStyle='labelStyle'>{{dataInfo.job.pz_time}}</el-descriptions-item>
          <el-descriptions-item label="就 诊 人" :labelStyle='labelStyle'>{{dataInfo.job.pz_serve_name}}&nbsp;&nbsp;{{dataInfo.job.pz_serve_mobile}}&nbsp;&nbsp;{{dataInfo.job.pz_serve_relation_str}}</el-descriptions-item>
          <el-descriptions-item label="身份证号" :labelStyle='labelStyle'>{{dataInfo.job.pz_serve_id_card}}</el-descriptions-item>
          <el-descriptions-item label="需要陪诊时间" :labelStyle='labelStyle'>{{dataInfo.job.pz_duration == 1 ? '半天' : '一天'}}</el-descriptions-item>
          <el-descriptions-item label="是否需要接送" :labelStyle='labelStyle'>{{dataInfo.job.pz_serve == 1 ? '是' : '否'}}</el-descriptions-item>
          <el-descriptions-item label="服务地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item label="需求详情" :labelStyle='labelStyle'>{{dataInfo.job.content}}</el-descriptions-item>
          <!-- 做饭阿姨用工时间 food_time -->
          <template v-if="dataInfo.job.kinds === 183">
            <el-descriptions-item label="用工时间" :labelStyle='labelStyle'>{{dataInfo.job.food_time}}</el-descriptions-item>
            <el-descriptions-item label="做菜数量" :labelStyle='labelStyle'>{{dataInfo.job.food_num}} 个</el-descriptions-item>
            <el-descriptions-item label="菜系口味" :labelStyle='labelStyle'>{{dataInfo.job.food_flavor}}</el-descriptions-item>
            <el-descriptions-item label="是否需要买菜" :labelStyle='labelStyle'>{{dataInfo.job.food_buy == 1 ? '是' : '否'}}</el-descriptions-item>
          </template>
          <el-descriptions-item v-else label="用工时间" :labelStyle='labelStyle'>{{dataInfo.job.start_time}} - {{dataInfo.job.start_time}}</el-descriptions-item>
          
          <el-descriptions-item v-if="dataInfo.job.kinds === 191" label="医院地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
          <el-descriptions-item v-else-if="dataInfo.job.kinds === 192" label="购买地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
          <el-descriptions-item v-else label="用工地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
        </template>
        
        <el-descriptions-item label="工单金额" :labelStyle='labelStyle'>
          <template v-if="dataInfo.job.price_type == 1 || dataInfo.job.price_type == 3">
            ￥{{dataInfo.job.amount}}&nbsp;&nbsp;
            <span style="font-size:10px;color:#1966FF">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},dataInfo.job.price_type)}}</span>
          </template>
          <!-- 如果用户选择的是对方报价，则取order对象中total_amount -->
          <template v-else>
            {{dataInfo.order.total_amount ? `¥${dataInfo.order.total_amount}` : '待报价'}}
            <span style="font-size:10px;color:#1966FF">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},dataInfo.job.price_type)}}</span>
          </template>
        </el-descriptions-item>
        <!-- 只有平台专享价有支付状态 -->
        <el-descriptions-item label="支付状态" v-if="dataInfo.job.price_type == 3">{{toStr({0:'未支付',1:'已支付',2:'支付中'},dataInfo.job.is_pay)}}</el-descriptions-item>
        <el-descriptions-item label="浏览量" :labelStyle='labelStyle'>{{dataInfo.job.browse_count}}次</el-descriptions-item>
        <el-descriptions-item label="报名人数" :labelStyle='labelStyle'>{{dataInfo.job.apply_num}}人</el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="工单信息" :column="2" border style="margin-top:20px;">
        <el-descriptions-item label="选择工人">{{dataInfo.order.name}}</el-descriptions-item>
        <el-descriptions-item label="性别">{{dataInfo.order.sex === 1 ? '男' : '女'}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{dataInfo.order.phone}}</el-descriptions-item>
        <!-- 平台专享价时显示接单时间 -->
        <el-descriptions-item label="接单时间" v-if="dataInfo.job.price_type == 3">{{dataInfo.order.choose_time}}</el-descriptions-item>
        <template v-else>
          <el-descriptions-item label="报名时间">{{dataInfo.order.apply_time}}</el-descriptions-item>
          <el-descriptions-item label="选择时间">{{dataInfo.order.choose_time}}</el-descriptions-item>
          <el-descriptions-item label="工单金额">¥{{dataInfo.order.total_amount}}</el-descriptions-item>
        </template>
        <el-descriptions-item label="代理商收益">¥{{dataInfo.order.agency_amount}}（{{dataInfo.order.agency_rate}}%）</el-descriptions-item>
        <!-- <el-descriptions-item label="平台收益">¥{{earnings}}（{{100 - Number(dataInfo.order.agency_rate) - Number(dataInfo.order.woker_rate)}}%）</el-descriptions-item> -->
        <el-descriptions-item label="师傅收益">¥{{dataInfo.order.woker_amount}}（{{dataInfo.order.woker_rate}}%）</el-descriptions-item>

        <!-- <el-descriptions-item label="服务类型">{{dataInfo.order.service_type === 1 ? '包工包料' : '包工不包料'}}</el-descriptions-item> -->
        <!-- <el-descriptions-item label="预付金额">¥{{dataInfo.order.prepay_amount}}</el-descriptions-item> -->
        <!-- <el-descriptions-item label="尾款金额">¥ {{dataInfo.order.total_amount - dataInfo.order.prepay_amount}}</el-descriptions-item> -->
        <!-- 状态不是待确认时都展示确认时间 -->
        <!-- <template v-if="dataInfo.order.status !== 1">
          <el-descriptions-item label="确认时间">{{dataInfo.order.check_time}}</el-descriptions-item>
        </template> -->
        <!-- 待完工 -->
        <template v-if="dataInfo.order.status === 3">
          <el-descriptions-item label="开工时间">{{dataInfo.order.work_time || ''}}</el-descriptions-item>
        </template>
        <!-- 待结算 -->
        <template v-if="dataInfo.order.status === 4">
          <el-descriptions-item label="开工时间">{{dataInfo.order.work_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="完工时间">{{dataInfo.order.complete_time}}</el-descriptions-item>
        </template>
        <!-- 已完成 -->
        <template v-if="dataInfo.order.status === 5">
          <el-descriptions-item label="开工时间">{{dataInfo.order.work_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="完工时间">{{dataInfo.order.complete_time}}</el-descriptions-item>
          <!-- <el-descriptions-item label="结算时间">{{dataInfo.order.settlement_time}}</el-descriptions-item> -->
        </template>
        <!-- 申请取消中/或申请退款中 -->
        <template v-if="dataInfo.order.cancel_type > 0 && dataInfo.order.status > 4">
          <el-descriptions-item label="申请取消时间">{{dataInfo.order.apply_cancel_time}}</el-descriptions-item>
        </template>
        <!-- 师傅已取消 -->
        <template v-if="dataInfo.order.cancel_type > 0 && dataInfo.order.status < 4 ">
          <el-descriptions-item label="申请取消时间">{{dataInfo.order.apply_cancel_time}}</el-descriptions-item>
          <el-descriptions-item label="对方同意时间">{{dataInfo.order.cancel_time}}</el-descriptions-item>
        </template>

        <el-descriptions-item label="付款时间">{{dataInfo.order.pay_time || ''}}</el-descriptions-item>
        <el-descriptions-item label="状态">
           <span>{{dataInfo.order.cancel_type ? (dataInfo.order.status > 4 ? cancel[dataInfo.order.cancel_type] : applyCancel[dataInfo.order.cancel_type]) :status[dataInfo.order.status]}}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-row>
        <el-col :span="12">
          <!-- 待完工、已完成 展示开工打卡图片 -->
          <template v-if="dataInfo.order.status === 3 || dataInfo.order.status === 5">
            <el-descriptions title="开工打卡图片" :column="2" :colon="false" style="margin-top:20px">
              <el-descriptions-item label="">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="dataInfo.order.clock_image"
                  fit="cover"
                  :preview-src-list="[dataInfo.order.clock_image]">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </el-col>
        <el-col :span="12">
          <!-- 已完成展示完工打卡图片 -->
          <template v-if="dataInfo.order.status === 5">
            <el-descriptions title="完工打卡图片" :column="2" :colon="false" style="margin-top:20px">
              <el-descriptions-item label="">
                <span v-for="img in dataInfo.order.complete_image" :key="img" style="margin-right:10px;">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="img"
                    fit="cover"
                    :preview-src-list="dataInfo.order.complete_image"></el-image>
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        job: {},
        order: {}
      },
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      status:{
        0:'待确认',
        1:'待确认',
        2:'待开工',
        3:'待完工',
        4:'待结算',
        5:'已完成'
      },
      applyCancel:{
        1:"申请退款",
        2:"申请取消"
      },
      cancel:{
        1:"老板已经取消",
        2:"师傅已经取消"
      },
      labelStyle: { 'width': '160px' },
      earnings: 0,
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.isChange = true
      this.$http.get('/government/order/info', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data
          // common.deepClone(this.dataInfo, res.data)
          this.dataInfo.happen_at = this.$moment(res.data.created_at).format('YYYY-MM-DD')
          this.earnings = parseFloat(this.dataInfo.order.total_amount - this.dataInfo.order.agency_amount - this.dataInfo.order.woker_amount).toFixed(2)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    .info-top{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }
}
.avatar_img ::v-deep img {
  width: 100%;
  height: 100%;
}
</style>

